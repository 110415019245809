<template>
  <side-bar></side-bar>
  <loading v-if="isLoading" :startPosition="200"></loading>
  <div id="case" v-if="currentCase._id">
    <a class="btn-return" @click="$router.go(-1)"><ion-icon name="chevron-back-outline"></ion-icon> Return</a>
    <div class="case-top">
      <div class="row">
        <h2>Case #{{ caseId.slice(caseId.indexOf('-') + 1) }}</h2>
        <div class="case-recommendations" :class="[recommendationsAvailable ? 'available' : 'unavailable']">
          {{ recommendationsAvailable ? "recommendations available" : "no new recommendations" }}
        </div>
      </div>

      <div class="stats">
        <div v-if="parameters.kpi" class="targets-container shadow hide-scrollbar">
          <div v-for="(positiveOutcomeGroup,index1) in parameters.kpi" :key="index1" class="row align-center">
            <small class="outcome-grouping" v-if="index1 > 0">or</small>
            <div v-for="(positiveOutcomeItem,index2) in positiveOutcomeGroup" :key="index2" class="row align-center">
              <small class="outcome-grouping" v-if="index2 > 0">and</small>
              <div class="stats-card column">
                <p>Target</p>
                <h3 class="blue target-value">{{ positiveOutcomeItem.value }} {{ positiveOutcomeItem.unit }}</h3>
                <small>Case {{ positiveOutcomeItem.column }} {{ positiveOutcomeItem.operator }}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="stats-card case-information hide-scrollbar">
          <div v-for="(caseOutcomeGroup,index1) in caseKpi" :key="index1" class="row">
            <div v-for="(caseOutcomeItem,index2) in caseOutcomeGroup" :key="index2" class="row">
              <div class="case-performance">
                <p>Case performance</p>
                <p :class="['bold', caseOutcomeItem.outcome ? 'green' : 'warning']">{{ caseOutcomeItem.value }} {{ caseOutcomeItem.unit }}</p>
                <small>Case {{ caseOutcomeItem.column }}</small>
              </div>
            </div>
          </div>
          <div v-if="Object.keys(caseDetails).length > 0" class="case-details">
            <p>Case details</p>
            <div class="row">
              <div class="column" v-for="(value, name) in caseDetails" :key='name'>
                <p class="bold">{{ value }}</p>
                <small>{{ name + (parameters.costUnits[name] ? ' (' + parameters.costUnits[name] + ')' : '') }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <manager-view v-show="selectedView === 'tactical'" :currentCase="currentCase" :parameters="parameters"></manager-view>
    <analytical-view v-show="selectedView === 'analytical'" :currentCase="currentCase" :parameters="parameters"></analytical-view>
    <operational-view v-show="selectedView === 'operational'" :currentCase="currentCase" :parameters="parameters"></operational-view>
  </div>
  <error v-else></error>
</template>

<script>
import casesService from "@/services/cases.service";
import logsService from "@/services/logs.service";
import OperationalView from '@/components/casePage/OperationalViewComponent.vue';
import AnalyticalView from '@/components/casePage/AnalyticalViewComponent.vue';
import ManagerView from '@/components/casePage/ManagerViewComponent.vue';
import SideBar from '@/components/SideBarComponent.vue';
import Loading from "@/components/LoadingComponent.vue";
import Error from "@/components/ErrorComponent.vue";
import utils from "@/common/utils";

export default {
  name: 'CasePage',
  components: {
    SideBar,
    OperationalView,
    AnalyticalView,
    ManagerView,
    Loading,
    Error
  },
  params: {
    caseId: {
      type: Number
    }
  },
  data() {
    return {
      timer: null,
      isLoading: true,
      currentCase: {},
      parameters: {},
      selectedView: null,
      caseKpi: { value: null, column: null, outcome: false },
      caseDetails: {},
      recommendationsAvailable: false,
    };
  },
  mounted() {
    window.addEventListener('view-changed', this.changeView);
    this.selectedView = utils.getLocal('view');
    if (utils.getLocal('logId')) {
      this.getParameters();
      this.getProjectStatus();
    }
  },
  beforeUnmount() {
    window.removeEventListener('view-changed', this.changeView);
    clearInterval(this.timer);
  },
  methods: {
    getCase() {
      this.caseId = (this.$route.params.caseId);
      casesService.getCase(this.caseId).then(
        (response) => {
          if (this.currentCase.activities) {
            if (this.currentCase.activities.length === response.data.case.activities.length) return;
          }
          this.currentCase = response.data.case;
          this.getAdditionalInformation();
        }
        // ,
        // (error) => {
        //   this.isLoading = false;
        //   const resMessage =
        //     (error.response &&
        //       error.response.data &&
        //       error.response.data.error) ||
        //     error.message ||
        //     error.toString();
        //   this.$notify({
        //     title: 'An error occured',
        //     text: resMessage,
        //     type: 'error'
        //   })
        // }
      );
    },
    getParameters() {
      logsService.getParameters(utils.getLocal('logId')).then(
        (response) => {
          this.parameters = response.data.parameters;
          if (!this.parameters.costUnits) this.parameters.costUnits = {};
          if(!Array.isArray(this.parameters.kpi)) this.parameters.kpi = [[this.parameters.kpi]];
          this.getCase();
        },
        (error) => {
          this.isLoading = false;
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.error) ||
            error.message ||
            error.toString();
          this.$notify({
            title: 'An error occured',
            text: resMessage,
            type: 'error'
          })
        }
      );
    },
    getAdditionalInformation() {
      this.caseDetails = this.currentCase.case_attributes;
      const caseActivities = this.currentCase.activities
      this.recommendationsAvailable = caseActivities[caseActivities.length - 1]['prescriptions'].length === 0 ? false : true;

      if (this.currentCase.case_performance) {
        this.caseKpi = this.currentCase.case_performance;
        if(!Array.isArray(this.caseKpi)) this.caseKpi = [[this.caseKpi]];
      }
      this.isLoading = false;
    },
    changeView(event) {
      this.selectedView = event.detail.storage;
    },
    getProjectStatus() {
      logsService.getProjectStatus(utils.getLocal('logId')).then(
        (response) => {
          let status = response.data.status;
          if (status === 'SIMULATING') {
            this.timer = setInterval(() => {
              this.getCase();
            }, 5000);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
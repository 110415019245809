<template>
  <side-bar></side-bar>
  <loading v-if="isLoading" :startPosition="200"></loading>
  <div id="recommendations">
    <h2>Recommendations</h2>
    <div class="stats">
      <div class="stats-card">
        <div class="column">
          <small>Total current recommendations</small>
          <div class="row">
            <h4>{{ formattedData ? formattedData.length : 0 }}</h4>
          </div>
        </div>
        <ion-icon name="albums"></ion-icon>
      </div>
      <template v-if="selectedView === 'tactical'">
        <div v-if="parameters.kpi">
          <div class="stats-card">
            <div v-for="(positiveOutcomeGroup, index1) in parameters.kpi" :key="index1" class="row align-center">
              <small class="outcome-grouping" v-if="index1 > 0">or</small>
              <div v-for="(positiveOutcomeItem, index2) in positiveOutcomeGroup" :key="index2" class="row align-center">
                <small class="outcome-grouping" v-if="index2 > 0">and</small>
                <PositiveOutcomeItemComponent v-if="positiveOutcomeItem" :object="positiveOutcomeItem"/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <button :disabled="formattedData.length < 1" class="btn-blue" @click="exportData()">Export recommendations as
        CSV
      </button>
    </div>

    <div class="recommendations-table">
      <table-lite :is-hide-paging="true" :is-slot-mode="true" :columns="table.headers" :rows="table.rows"
                  :total="table.rows.length" :sortable="table.sortable" @do-search="doSort" @row-clicked="rowClicked">
        <template v-slot:id="data">
          <p>{{ formatId(data.value.id) }}</p>
        </template>
        <template v-slot:performance="data">
          <p>{{ data.value.performance.value }} {{ data.value.performance.unit }}</p>
        </template>
      </table-lite>
    </div>

  </div>
</template>

<script>
import logsService from "@/services/logs.service";
import SideBar from '@/components/SideBarComponent.vue';
import Loading from "@/components/LoadingComponent.vue";
import TableLite from "vue3-table-lite";
import utils from '@/common/utils';
import PositiveOutcomeItemComponent from "@/components/PositiveOutcomeItemComponent";
import casesService from "@/services/cases.service";

export default {
  name: 'CasesList',
  components: {
    SideBar,
    Loading,
    TableLite,
    PositiveOutcomeItemComponent
  },
  data() {
    return {
      timer: null,
      isLoading: false,
      recommendations: [],
      formattedData: [],
      parameters: null,
      selectedView: null,
      caseAttributes: [],
      cases: [],
      casesData: [],
      table: {
        isLoading: false,
        headers: [
          {
            label: 'Case ID',
            field: 'id',
            width: "10%",
            sortable: true,
          },
          {
            label: "Performance",
            field: "performance",
            width: "10%",
            sortable: true,
          },
          {
            label: "Recommendation",
            field: "recommendationAttribute",
            width: "10%",
            sortable: false,
          },
          {
            label: "Details",
            field: "details",
            width: "20%",
            sortable: false,
          },
        ],
        rows: [],
        sortable: {
          order: utils.getLocal('recommendationsOrder'),
          sort: utils.getLocal('recommendationsSort')
        },
      },
    };
  },
  watch: {
    selectedView() {
      this.setup();
    }
  },
  mounted() {
    window.addEventListener('view-changed', this.changeView);
    this.selectedView = utils.getLocal('view');
    this.setup();
  },
  beforeUnmount() {
    window.removeEventListener('view-changed', this.changeView);
    clearInterval(this.timer);
  },
  methods: {
    setup() {
      this.isLoading = true;
      if (this.selectedView === 'tactical') {
        this.getParametersManager();
        this.getCases();
      } else {
        this.getParameters();
        this.getCases();
      }
    },
    formatId(id) {
      if (!id) return null;
      return id.slice(id.indexOf('-') + 1);
    },
    rowClicked(row) {
      this.$router.push({name: 'case', params: {'caseId': row.id, 'completion': 'ongoing'}});
    },
    getCases() {
      casesService.getCasesByLogAndCompletion(utils.getLocal('logId'), 'ongoing').then(
          (response) => {
            if (response.data.cases && response.data.cases.length > 0) {
              this.cases = response.data.cases;
              console.log('Cases retrieved:', this.cases);
              this.formatCases();
            } else {
              this.isLoading = false;
            }
          },
          (error) => {
            this.isLoading = false;
            if (error.response.status === 504) return;
            const resMessage =
                (error.response && error.response.data && error.response.data.error) ||
                error.message ||
                error.toString();
            this.$notify({
              title: 'An error occured',
              text: resMessage,
              type: 'error'
            });
          }
      );
    },
    async formatCases() {
      if (this.cases.length > 0) {
        await this.getParameters();
        if (this.cases[0].case_attributes) {
          Object.keys(this.cases[0].case_attributes).forEach(k => {
            this.caseAttributes.push({
              label: k + (this.costUnits[k] ? ' (' + this.costUnits[k] + ')' : ''),
              field: k,
              sortable: true
            });
          });
          console.log('Case attributes set:', this.caseAttributes);
        } else {
          console.error('case_attributes is undefined');
        }

        let data = {};
        for (const el of this.cases) {
          data = utils.formatCase(el, this.alarmThreshold);
          this.casesData.push(data);
        }
        console.log('Formatted cases data:', this.casesData);
      } else {
        console.error('Cases data is empty');
      }
      this.isLoading = false;
    },
    doSort(offset, limit, order, sort) {
      const sortOrder = sort === 'asc' ? 1 : -1;
      this.table.rows = this.table.rows.sort((a, b) => (a[order] > b[order]) ? (1 * sortOrder) : (-1 * sortOrder));
      utils.setLocal('recommendationsOrder', order, 30);
      utils.setLocal('recommendationsSort', sort, 30);
      this.table.sortable.order = order;
      this.table.sortable.sort = sort;
    },
    async exportData() {
      if (!this.recommendations.length) {
        this.isLoading = true;
        try {
          const response = await logsService.getRecommendations(utils.getLocal('logId'));
          this.recommendations = response.data.prescriptions;
          if (this.recommendations.length > 0) {
            if (this.selectedView === 'tactical') {
              this.formatRecommendationsManager();
            } else {
              this.formatRecommendations();
            }
          }
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          const resMessage = (error.response && error.response.data && error.response.data.error) || error.message || error.toString();
          this.$notify({
            title: 'An error occurred',
            text: resMessage,
            type: 'error'
          });
          return;
        }
      }

      let csv = this.table.headers.map(h => h.label).join(',');
      this.table.rows.forEach((row) => {
        let rowData = Object.values(row).map(data => {
          if (data.value) {
            return [data.value, data.unit].filter(Boolean).join(' ')
          } else {
            return data
          }
        });
        csv += "\n";
        csv += rowData.join(',');
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'recommendations.csv';
      anchor.click();
    },
    async getRecommendations() {
      try {
        const response = await logsService.getRecommendations(utils.getLocal('logId'));
        this.recommendations = response.data.prescriptions;
        if (this.recommendations.length > 0) {
          if (this.selectedView === 'tactical') {
            this.formatRecommendationsManager();
          } else {
            this.formatRecommendations();
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        const resMessage = (error.response && error.response.data && error.response.data.error) || error.message || error.toString();
        this.$notify({
          title: 'An error occurred',
          text: resMessage,
          type: 'error'
        });
      }
    },
    getParameters() {
      logsService.getParameters(utils.getLocal('logId')).then(
          (response) => {
            this.parameters = response.data.parameters;
            this.alarmThreshold = response.data.parameters.alarmThreshold || 1.0;
            this.costUnits = response.data.parameters.costUnits || {};
            this.columnsDefinition = response.data.parameters.columnsDefinition;
            console.log('Parameters retrieved:', response.data.parameters);
          },
          (error) => {
            this.isLoading = false;
            const resMessage = (error.response && error.response.data && error.response.data.error) || error.message || error.toString();
            this.$notify({
              title: 'An error occurred',
              text: resMessage,
              type: 'error'
            });
          }
      );
    },
    getParametersManager() {
      logsService.getParameters(utils.getLocal('logId')).then(
          (response) => {
            this.parameters = response.data.parameters;
            if (!this.parameters.costUnits) this.parameters.costUnits = {};
            if (!Array.isArray(this.parameters.kpi)) this.parameters.kpi = [[this.parameters.kpi]];
            this.getRecommendations();
          },
          (error) => {
            this.isLoading = false;
            const resMessage = (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                error.message ||
                error.toString();
            this.$notify({
              title: 'An error occurred',
              text: resMessage,
              type: 'error'
            });
          }
      );
    },
    formatRecommendations() {
      this.formattedData = [];
      for (const el of this.recommendations) {
        var caseId = el._id;
        var casePerformance = el.case_performance;
        for (const batch of el.activities) {
          for (const rec of batch.prescriptions) {
            let data = this.formatRecommendation(caseId, casePerformance, rec);
            if (data !== null) this.formattedData.push(data);
          }
        }
      }
      this.table.rows = this.formattedData;
      this.doSort(null, null, this.table.sortable.order, this.table.sortable.sort);
    },

    formatRecommendationsManager() {
      this.formattedData = [];
      for (const el of this.recommendations) {
        var caseId = el._id;
        var casePerformance = el.case_performance;
        for (const batch of el.activities) {
          for (const rec of batch.prescriptions) {
            let data = this.formatRecommendationManager(caseId, casePerformance, rec);
            if (data !== null) this.formattedData.push(data);
          }
        }
      }
      this.table.rows = this.formattedData;
      this.doSort(null, null, this.table.sortable.order, this.table.sortable.sort);
    },
    formatRecommendation(id, performance, p) {
      let recommendationAttr, recommendedAttr;
      if (p.type === 'NEXT_ACTIVITY') {
        return null;
      }

      if (p.type === 'ALARM') {
        recommendationAttr = 'Check the application';
        if (p.output < this.parameters.alarmThreshold) return null;
        recommendedAttr = 'Probability of not meeting the KPI is high. It is recommended to check on the application.';
      } else if (p.type === 'TREATMENT_EFFECT') {
        recommendationAttr = utils.formatIntervention(p.output, this.parameters.columnsDefinition);
        if (p.output.cate <= 0) return null;

        recommendedAttr = 'There is a high probability of reaching the KPI if you address this recommendation now.';
      } else if (p.type === 'RESOURCE_ALLOCATION') {
        return null;
      }


      let data = {
        id: id,
        performance: {value: performance.value, unit: performance.unit},
        recommendationAttribute: recommendationAttr,
        details: recommendedAttr,
      };
      return data;
    },
    formatRecommendationManager(id, performance, p) {
      let recommendationAttr, recommendedAttr;
      if (p.type === 'NEXT_ACTIVITY') {
        return null;
      }

      if (p.type === 'ALARM') {
        recommendationAttr = 'Check the application';
        if (p.output < this.parameters.alarmThreshold) return null;
        recommendedAttr = 'Probability of not meeting the KPI is high. It is recommended to check on the application.';
      } else if (p.type === 'TREATMENT_EFFECT') {
        recommendationAttr = utils.formatIntervention(p.output, this.parameters.columnsDefinition);
        if (p.output.cate <= 0) return null;

        recommendedAttr = 'There is a high probability of reaching the KPI if you address this recommendation now.';
      } else if (p.type === 'RESOURCE_ALLOCATION') {
        recommendationAttr = utils.formatIntervention(p.output, this.parameters.columnsDefinition);
        if (p.output.cate <= 0) return null;

        recommendedAttr = 'Resource allocation';
      }


      let data = {
        id: id,
        performance: {value: performance.value, unit: performance.unit},
        recommendationAttribute: recommendationAttr,
        details: recommendedAttr,
      };
      return data;
    },
    getProjectStatus() {
      logsService.getProjectStatus(utils.getLocal('logId')).then(
          (response) => {
            let status = response.data.status;
            if (status === 'SIMULATING') {
              this.timer = setInterval(() => {
                this.getRecommendations();
              }, 4000);
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    changeView(event) {
      this.selectedView = event.detail.storage;
      this.setup();
    }
  }
};
</script>